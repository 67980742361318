import { SearchRow } from "./common";
import TrafficSummary from "./TrafficSummary";

const TrafficAnalytics = () => {

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6 my-4">
        <div className="grid gap-7">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Hub History
          </h3>
          <SearchRow />
        </div>
        <TrafficSummary />
      </div>
      
    </section>
  );
};

export default TrafficAnalytics;
