import { RefObject, useRef, useState } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import useOutsideClick from "src/hooks/useOutsideClick";
import { InfoBoxProps, ProgressBoxProps } from 'types';
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { ButtonYellow, Filter } from "../reusables";
import Export from '../../assets/icons/export.svg';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { ReactComponent as VerticalEllipsisIcon } from "../../assets/icons/hotspot-hubs/vertical-ellipsis.svg";


export const SpeedDetail = ({
  imgSrc,
  title,
  value,
}: {
  imgSrc: string;
  title: string;
  value: string;
}) => {
  return (
    <div className="detail-item border border-[#FFCB0533] flex items-center gap-x-3 p-2 rounded-xl">
      <img alt="speed" src={imgSrc} className="h-5" />
      <div className="text-black text-[10.5px]">
        <p className="mb-1 text-clrGray">{title}</p>
        <p className="font-semibold">{value}</p>
      </div>
    </div>
  );
};

export const InfoBox = ({ label, value, flex, offline }: InfoBoxProps) => (
  <div className={`flex gap-3 ${flex ? "mt-0 w-full" : "mt-6"}`}>
    <div
      className={` w-1 rounded ${offline ? 'bg-[#FFECE5]' : 'bg-primary'}  ${
        !flex ? 'h-[46px]' : 'h-[23px]'
      }`}
    />
    <div className={`${flex ? 'flex gap-x-10 items-center justify-between w-full' : ''}`}>
      <p className={`text-clrTextLightGray text-xs ${!flex && 'mb-2'}`}>{label}</p>
      <p className="text-sm font-medium text-black">{value}</p>
    </div>
  </div>
);

export const ProgressBox = ({
  title,
  progressValue,
  text,
  info,
}: ProgressBoxProps) => (
  <div className="border border-[#C4C4C433] p-5 rounded-lg">
    <p className="mb-5 text-xs font-semibold text-clrTextLightGray">{title}</p>
    <div style={{ width: 200, height: 200, position: 'relative' }}>
      <CircularProgressbar
        maxValue={300}
        minValue={0}
        strokeWidth={12}
        value={progressValue}
        counterClockwise={true}
        styles={{
          path: {
            stroke: `#FFCB05`,
            strokeLinecap: 'square',
          },
          trail: {
            stroke: '#FFECE5',
          },
          text: {
            fill: '#1D1D1D',
            fontSize: '20px',
          },
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: '#1D1D1D',
          fontSize: '18px',
        }}
      >
        <div className="text-xs text-[#4E5969] mb-1">{text.label}</div>
        <span className="font-semibold">{text.value}</span>
      </div>
    </div>
    <div className="grid justify-end gap-4">
      {info.map((item, index) => (
        <InfoBox
          key={index}
          label={item.label}
          value={item.value}
          flex={item.flex}
          offline={item.offline}
        />
      ))}
    </div>
  </div>
);


export const SearchRow = () => {
  const [filterStatus, setFilterStatus] = useState<string>('By year');
  const [option, toggleOption] = useState<string>("");
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [yearOption, toggleYearOption] = useState<string>("");

  // New function to handle filter status
  const handleFilterStatus = (status: string) => {
    if (status === 'All') {
      setFilterStatus('');
      setFilterYear(''); // Set to empty string if "All" is selected
    } else {
      setFilterStatus(status); // Otherwise, set to the selected status
    }
  };

  const handleFilterYear = () => {};

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };
  useOutsideClick(modalRef, handleCloseModal);
  return (
    <>
       
        <div className="flex flex-col flex-wrap w-full gap-x-3 md:flex-row md:w-auto">
          <MobileFilterSearch>
            <Filter
              options={['By year', 'By month', 'By week', 'By day']}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={'TIMELINE'}
              selectFilterOption={handleFilterStatus}
              selectedOption={filterStatus}
              modalRef={modalRef}
              label={'Timeline'}
            />
            <Filter
              options={['2024', '2025', '2026']}
              toggleFilter={toggleYearOption}
              selectedFilterOption={yearOption}
              currentFilterOption={'YEAR'}
              selectFilterOption={handleFilterYear}
              selectedOption={filterYear}
              modalRef={modalRef}
              label={'Year'}
            />
          </MobileFilterSearch>
          <div className="mt-2 md:mt-3">
            <ButtonYellow text="Export" btnIcon={Export} to={''} />
          </div>
        </div>
    </>
  )
}

interface UptimeCardProps {
  title: string;
  data: string | number | null;
  isLoading: boolean;
}

export const UptimeCard: React.FC<UptimeCardProps> = ({ title, data, isLoading }) => {
  return (
    <div className="border border-[#C4C4C433] p-5 rounded-lg">
      <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
        {title}
      </h4>
      <p className="text-2xl font-semibold text-black dark:text-white">
        {isLoading ? (
          <ScaleLoader height={12} width={2} color={'#3d5170'} />
        ) : (
          data
        )}
      </p>
    </div>
  );
};

// interface IUptimeSummary {
//   uptimeSummary: string;
//   gettingUptimeSummary: string;
// }

// export const UptimeSummary = ({ uptimeSummary, gettingUptimeSummary }: IUptimeSummary) => {
//   const uptimeMetrics = [
//     { title: 'Minimum Uptime', key: 'minimumUptime' },
//     { title: 'Average Uptime', key: 'averageUptime' },
//     { title: 'Maximum Uptime', key: 'maximumUptime' },
//     { title: 'Max Uptime of a Connected Device', key: 'maximumUptimeOfAConnectedDevice' },
//     { title: 'Min Uptime of a Connected Device', key: 'minimumUptimeOfAConnectedDevice' },
//   ];

//   return (
//     <div className="grid grid-cols-2 gap-2 py-4 rounded-lg md:pt-5 md:pb-4 xl:grid-cols-4 md:gap-4">
//       {uptimeMetrics.map((metric) => (
//         <UptimeCard
//           key={metric.key}
//           title={metric.title}
//           data={uptimeSummary?.data?.[metric.key]}
//           isLoading={gettingUptimeSummary}
//         />
//       ))}
//     </div>
//   );
// };

interface DeviceOverviewBoxProps {
  title: string;
  count: number | undefined;
  loading: boolean;
}

export const DeviceOverviewBox: React.FC<DeviceOverviewBoxProps> = ({ title, count, loading }) => {
  return (
    <div className="border border-[#C4C4C433] p-4 rounded-lg bg-white dark:bg-black">
      <h4 className="mb-2 text-x9 font-normal md:text-x10 text-clrTextLightGray dark:text-clrPlaceholder">
        {title}
      </h4>
      <p className="text-base font-medium text-[#000] dark:text-white">
        {loading ? (
          <ScaleLoader loading={loading} height={12} width={2} color={'#1D1D1D'} />
        ) : (
          count || 0
        )}
      </p>
    </div>
  );
};

interface OptionForConnectionProps {
  modalRef: RefObject<HTMLElement>;
  id?: string;
  closeFilter: () => void;
}

export const OptionsForConnection: React.FC<OptionForConnectionProps> = ({
  modalRef,
  id,
  closeFilter,
}) => {

  const handleCloseModal = () => {
    closeFilter();
  };

  useOutsideClick(modalRef, handleCloseModal);

  const options = [
    { label: 'Restrict', action: () => handleRestrict(id) },
    { label: 'Whitelist', action: () => handleWhitelist(id) },
    { label: 'Disconnect', action: () => handleDisconnect(id) },
  ];

  const handleRestrict = (id?: string) => {
    console.log(`Restrict connection with id: ${id}`);
    // Add the actual logic here
  };

  const handleWhitelist = (id?: string) => {
    console.log(`Whitelist connection with id: ${id}`);
    // Add the actual logic here
  };

  const handleDisconnect = (id?: string) => {
    console.log(`Disconnect connection with id: ${id}`);
    // Add the actual logic here
  };

  return (
    <div>
      <div className="absolute z-30 w-32 p-3 pb-4 bg-white rounded dark:bg-clrDarkBg top-10 right-10">
        <ul className="space-y-2 text-xs font-normal text-[#6b6b6b] dark:text-clrPlaceholder">
          {options.map((option, index) => (
            <li
              key={index}
              className="py-1 pl-2 transition duration-150 ease-in rounded hover:bg-clrBorder dark:hover:bg-clrDarkBg2"
            >
              <button onClick={option.action}>
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
    </div>
  );
};


export const CellRendererAnalytics = ({ id }: { id?: string }) => {
  const [isOption, setIsOption] = useState(false);

  const modalRefOptions = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsOption(false);
  };

  useOutsideClick(modalRefOptions, handleCloseModal);

  return (
    <>
      <button
        onClick={() => {
          setIsOption(!isOption);
        }}
        className="px-2 py-1 hover:bg-[#e0dcdc] hover:rounded transition duration-500 ease-in-out"
      >
        <VerticalEllipsisIcon />
      </button>
      {isOption && (
        <OptionsForConnection
          closeFilter={handleCloseModal}
          modalRef={modalRefOptions}
          id={id}
        />
      )}
    </>
  );
};

export const ConnectionSearchRow = () => {
  const [filterStatus, setFilterStatus] = useState<string>('By year');
  const [option, toggleOption] = useState<string>("");
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [yearOption, toggleYearOption] = useState<string>("");
  const [filterMonth, setFilterMonth] = useState<string>('January');
  const [monthOption, toggleMonthOption] = useState<string>("");

  // New function to handle filter status
  const handleFilterStatus = (status: string) => {
    if (status === 'All') {
      setFilterStatus('');
      setFilterYear('');
      setFilterMonth(''); // Set to empty string if "All" is selected
       // Set to empty string if "All" is selected
    } else {
      setFilterStatus(status); // Otherwise, set to the selected status
    }
  };

  const handleFilterYear = () => {};
  const handleFilterMonth = () => {};

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };
  useOutsideClick(modalRef, handleCloseModal);
  return (
    <>
       
        <div className="flex flex-col flex-wrap w-full gap-x-3 md:flex-row md:w-auto">
          <MobileFilterSearch>
            <Filter
              options={['By year', 'By month', 'By week', 'By day']}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={'TIMELINE'}
              selectFilterOption={handleFilterStatus}
              selectedOption={filterStatus}
              modalRef={modalRef}
              label={'Timeline'}
            />
            <Filter
              options={['January', 'February', 'March', ' April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              toggleFilter={toggleMonthOption}
              selectedFilterOption={monthOption}
              currentFilterOption={'MONTH'}
              selectFilterOption={handleFilterMonth}
              selectedOption={filterMonth}
              modalRef={modalRef}
              label={'Month'}
            />
            <Filter
              options={['2024', '2025', '2026']}
              toggleFilter={toggleYearOption}
              selectedFilterOption={yearOption}
              currentFilterOption={'YEAR'}
              selectFilterOption={handleFilterYear}
              selectedOption={filterYear}
              modalRef={modalRef}
              label={'Year'}
            />
          </MobileFilterSearch>
          <div className="mt-2 md:mt-3">
            <ButtonYellow text="Export" btnIcon={Export} to={''} />
          </div>
        </div>
    </>
  )
}