import { useState } from "react";
import Plan from "./Plan";

const Subscription = () => {
  const [planType, savePlan] = useState<number>(1);

    return(
        <section className="bg-clrBg w-[96%] mx-auto mt-3 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
            <div className=" bg-white rounded-lg py-[2.125rem] px-5 md:pl-11 md:pr-5">
                <div className="flex flex-col gap-2 mb-12 md:flex-row md:items-center md:justify-between">
                    <h3 className="text-base font-semibold text-clrGray">Choose a plan that is right for you</h3>
                    <div className=" bg-clrBg2 tracking-[0.01em] rounded-md tab-container flex w-fit">
                        <div className={planType === 1 ? "bg-primary rounded-md p-1 tab-option text-white" : " tab-option text-clrGray"} onClick={() => savePlan(1)}>
                            <p className="text-[10px] font-semibold">Montly Billing</p>
                        </div>
                        <div className={planType === 2 ? "bg-primary rounded-md p-1 tab-option text-white" : "tab-option text-clrGray"} onClick={() => savePlan(2)}>
                            <p className="text-[10px] font-semibold">Yearly billing <span className="text-x8"> (Save up to 40%)</span> </p>
                        </div>
                    </div>
                </div>
                <Plan planType={planType}/>
            </div>
        </section>
    )
}

export default Subscription