import { EmptyCard, TableLoader } from "../reusables";
import { useState } from "react";
import { useGetAgentsQuery } from "src/features/api/agentSlice";
import TableMain from "../reusables/ReactDataTable";
import { defaultTrafficDetailsData, trafficDetailsColumn } from "./data";
import { SearchRow } from "./common";

const TrafficDetails = () => {
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetAgentsQuery({
    page,
    pageSize: 30,
    agentName: '',
  });

  return (
    <section className="w-[96%] mx-auto pb-6 mt-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-8">
        <div className="grid gap-7">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            View History
          </h3>
          <SearchRow />
        </div>
        <div>
          {isLoading || isFetching ? (
              <TableLoader />
            ) : (
              <TableMain
                defaultData={defaultTrafficDetailsData}
                columns={trafficDetailsColumn}
                totalPages={data?.meta.numberOfPages || 0}
                pageSize={data?.meta.pageSize || 0}
                currentPage={data?.meta.page || 0}
                setPage={setPage}
              />
            )}

            {!isLoading && !isFetching && (data?.data.length || 0) === 0 && (
              <EmptyCard description="You don't have any traffic analytics!" />
            )}
        </div>
      </div>
      
    </section>
  );
};

export default TrafficDetails;