import { apiSlice } from "./apiSlice";
import {DashboardResponse, DashboardRevenue, MiningRewardResponse, RevenueRewardResponse, LinkCodeResponse, CustomCodeResponse} from "./homeSliceTypes";


const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

       //Get Dashboard Summary
        getDashboardSummary: build.query<DashboardResponse, void>({
            query: () => `/v4/users/dashboard`,
            providesTags: ['Agents', 'Vouchers', 'Agents', 'Devices']
        }),

        getUserRevenue: build.query<DashboardRevenue, void>({
            query: () => `/v4/users/dashboard/revenue`,
        }),

        getMiningReward: build.query<MiningRewardResponse, string>({
            query: (deviceId) => `/v4/miningReward?deviceId=${deviceId}`,
        }),
    
        getSessionHistory: build.query<RevenueRewardResponse, string>({
            query: (deviceId) => `/v4/sessions?deviceId=${deviceId}`,
        }),

        //Set Link Code
        linkCode: build.query<LinkCodeResponse, void>({
            query: () =>
                `/v4/hub-link-code`,
        }),

        // Get Custom Code
        customCode: build.query<CustomCodeResponse, void>({
            query: () =>
                `/v4/custom-code`,
            providesTags: ["CustomCode"]
        }),
        // Set a custom code
        setCustomCode: build.mutation<CustomCodeResponse, string>({
            query: (code) => ({
                url: `/v4/custom-code`,
                method: 'PUT',
                body: {code} 
            }),
            invalidatesTags: ["CustomCode"]
        }),


    }),
    overrideExisting: true,
})

export const { 
    useGetDashboardSummaryQuery, 
    useGetUserRevenueQuery, 
    useGetMiningRewardQuery, 
    useGetSessionHistoryQuery, 
    useLinkCodeQuery,
    useCustomCodeQuery,
    useSetCustomCodeMutation
} = dashboardSlice