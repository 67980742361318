import Moon from "../../assets/icons/moon.svg";
import Light from "../../assets/icons/setting.svg";
import useDarkMode from "../../hooks/useDarkSide"; // Import the hook

const LightDark = () => {
  const { theme, toggleTheme } = useDarkMode(); // Use the hook

  return (
    <div className="flex items-center gap-4 mt-7 pb-5 mb-5 border-b-[1px] border-solid border-clrBorder">
      <img
        src={theme === "dark" ? Light : Moon}
        alt="Toggle"
        width={15}
        height={15}
      />
      <label
        htmlFor="toggle-mode"
        className="mr-auto text-xs font-semibold text-clrGray dark:text-clrPlaceholder"
      >
        {theme === "dark" ? "Light Mode" : "Dark Mode"}
      </label>
      <label className="md_switch">
        <p className="hidden">Toggle</p>
        <input
          type="checkbox"
          id="toggle-mode"
          checked={theme === "dark"}
          onChange={toggleTheme}
        />
        <span className="md_switch__toggle"></span>
      </label>
    </div>
  );
};

export default LightDark;
