import { useParams } from 'react-router-dom';
import { useGetUptimeGraphResultsQuery, useGetDataUsageGraphResultsQuery } from 'src/features/api/analyticsSlice';
import FilterRow from "./FilterRow";
import { BarChart } from "./BarChartSession";
import { useState } from "react";
import { BarChartUptimeProps } from "./Uptime";
import { BarChartUsageProps } from "./DataUsage";


const ConnectionDetails = () => {
  const {analyticId } = useParams();
  // State for filters
  const [filterStatus, setFilterStatus] = useState<string>('Daily');
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [filterMonth, setFilterMonth] = useState<string>('01');

  const { data: uptimeGraph } = useGetUptimeGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterStatus,
    month: filterMonth,
    year: filterYear
  });

  const { data: dataUsageGraph } = useGetDataUsageGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterStatus,
    month: filterMonth,
    year: filterYear
  });

  const chartUptimeData: BarChartUptimeProps = {
    labels: uptimeGraph?.data.map(item => item.timePeriod) || [],
    data: uptimeGraph?.data.map(item => ({
      uptime: item.uptime,
      uptimeInSeconds: item.uptimeInSeconds,
      timePeriod: item.timePeriod,
    })) || []
  };

  const chartData: BarChartUsageProps = {
    labels: dataUsageGraph?.data.map(item => item.timePeriod) || [],
    data: dataUsageGraph?.data.map(item => ({
      dataUsed: item.dataUsed,
      dataUsedInBytes: item.dataUsedInBytes,
      timePeriod: item.timePeriod,
    })) || []
  };

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <div className="grid gap-1 mb-6 md:mb-8">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Data Usage
          </h3>
          <FilterRow
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterYear={filterYear}
            setFilterYear={setFilterYear}
            filterMonth={filterMonth}
            setFilterMonth={setFilterMonth}
          />
          <BarChart chartData={chartData} />
        </div>
        <div className="grid gap-4 pb-4">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Uptime{' '}
          </h3>
          <BarChart chartData={chartUptimeData} />
        </div>
      </div>
    </section>
  )
}

export default ConnectionDetails