import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from './config';

export const apiSlice = createApi({
  reducerPath: 'apiUrl',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
      user && headers.set('authorization', `Bearer ${user.accessToken}`);
      return headers;
    },
  }),
  tagTypes: [
    'Tokens',
    'Vouchers',
    'Agents',
    'Reward',
    'Wallet',
    'Devices',
    'Explorer',
    'Subscription',
    'Staking',
    'HubForms',
    'ParentalControl',
    'TwoFA',
    'CustomCode',
    'Analytics'
  ],
  endpoints: () => ({}),
});

export const clusterSlice = createApi({
  reducerPath: 'clusterUrl',
  baseQuery: fetchBaseQuery({
    baseUrl: config.clusterUrl,
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
      if (user) {
        headers.set('authorization', `Bearer ${user.accessToken}`);
        return headers;
      }
    },
  }),
  tagTypes: ['Cluster'],
  endpoints: () => ({}),
});

export const apiAdvertSlice = createApi({
  reducerPath: 'advertApiUrl',
  baseQuery: fetchBaseQuery({
    baseUrl: config.advertApiUrl,
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
      if (user) {
        headers.set('authorization', `Bearer ${user.accessToken}`);
        return headers;
      }
    },
  }),
  tagTypes: ['Devices'],
  endpoints: () => ({}),
});
