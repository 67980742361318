import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { Button } from '../reusables';
import CopyIcon from '../../assets/icons/copy.svg';
import ProfilePic from '../../assets/profile-pic.svg';
import Edit from '../../assets/edit.svg';
import { useNavigate } from 'react-router';
import {
  useGetBusinessProfileQuery,
  useGetUserProfileQuery,
} from '../../features/api/authSlice';
import { useUpdateProfileMutation } from '../../features/api/profileSlice';
import { copyToClipboard, maskPhoneNumber, truncateText } from '../../helper';
import { useDispatch } from 'react-redux';
import { setUser } from '../../features/store/authReducerSlice';
import { DeleteAccount } from './DeleteAccount';
import { ReactComponent as EditIcon } from "../../assets/hub/edit.svg";
import { useLinkCodeQuery } from 'src/features/api/homeSlice';
import { ScaleLoader } from 'react-spinners';
import { ChooseModal } from '../reusables/Modal';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { ReactComponent as Delete2 } from "../../assets/delete2.svg";
import { useCancelSubscriptionMutation, useGetActivePackageQuery } from 'src/features/api/subscriptionSlice';

const ProfileInfo = () => {
  const navigate = useNavigate();
  const { userId} = useAppSelector((state) => state.auth);

  const [profileUrl, setprofileUrl] = useState<string>('');
  const { verificationStatus, profileImageUrl } = useAppSelector(
    (state) => state.auth
  );
  const {
    data: businessProfile,
    isError,
    isLoading,
  } = useGetBusinessProfileQuery();
  const dispatch = useDispatch();
  const [copiedText, setCopied] = useState<string>('');
  const [errMsg, setErr] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [updateProfile] = useUpdateProfileMutation();
  const { data: userProfile } = useGetUserProfileQuery(userId, {
    skip: !userId,
  });

  // Cancel subscription
  const [isSubscriptionCancelled, toggleSubscription] = useState<boolean>(false);
  const modalRefSubscription = useRef<HTMLElement>(null);
  useOutsideClick(modalRefSubscription, ()=> toggleSubscription(!isSubscriptionCancelled));

  const { data: activePackage } = useGetActivePackageQuery(userId);

  // my link code
  const {data: linkCode, isLoading: gettingLinkCode} = useLinkCodeQuery();

  const handleCopyClick = (code: string) => {
    copyToClipboard(code);
    setCopied(code);

    setTimeout(() => {
        setCopied('');
    }, 3000);
  };

  // About phone number
  const handleEditClick = () => {
    navigate("/home/profile/edit-contact")
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErr('');
    const selectedFile = e.target.files?.[0];
    const maxSize = 256 * 1024; // 256 KB
    if (selectedFile && selectedFile.size <= maxSize) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setprofileUrl(blobUrl);
      handleProfileUpdate(selectedFile);
    } else {
      setErr('*Max size of image exceeded');
    }
  };

  const handleProfileUpdate = (selectedFile: File) => {
    let bodyFormData = new FormData();
    bodyFormData.append('FirstName', '');
    bodyFormData.append('LastName', '');
    bodyFormData.append('PhoneNumber', '');
    bodyFormData.append('ProfileImage', selectedFile);
    updateProfile(bodyFormData)
      .unwrap()
      .then((res) => {
        if (res) {
          const existingUser = localStorage.getItem('user');
          const userObject = JSON.parse(existingUser || '');
          userObject.profileImageUrl = res.data.profileImageUrl;
          dispatch(setUser(userObject));
        }
      })
      .catch((err) => {
        setErr(err?.data?.message || 'Something went wrong');
      });
  };

  // Cancel subscription
  const [cancelSubscription, { isLoading: cancelingSubscription }] =
  useCancelSubscriptionMutation();

  const handleCancelSub = () => {
    setErr("");
    let subscriptionId = activePackage?.data?.subscriptionId || ""
    cancelSubscription(subscriptionId as string)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          toggleSubscription(false);
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.message ? err?.message : "Something went wrong");
      });
  };

  useEffect(() => {
    setErr('');
  }, []);

  return (
    <section className="bg-clrBg w-[96%] mx-auto mt-3.5 md:mt-0 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="flex flex-wrap gap-10 px-4 py-4 bg-white dark:bg-clrDarkBg rounded-t-md md:rounded-md md:px-12 md:gap-24 md:flex-nowrap md:py-7">
        <div className="flex flex-col items-center w-full gap-5 md:w-fit md:pt-2">
          <div className="relative">
            <img
              src={
                profileUrl
                  ? profileUrl
                  : profileImageUrl
                  ? profileImageUrl
                  : ProfilePic
              }
              alt="Profile picture"
              width={150}
              height={150}
              className="rounded-full w-36 pic-aspect"
            />
            <button className="absolute right-0 cursor-pointer bottom-6">
              <div className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-primary">
                <img
                  src={Edit}
                  alt="Edit your profile here"
                  width={15}
                  height={15}
                />
                <input
                  type="file"
                  name="file"
                  id="idCard"
                  onChange={handleChange}
                  accept=".png, .jpeg, .jpg"
                  className="absolute w-4 overflow-hidden opacity-0 cursor-pointer z-index--1 md:w-auto"
                />
              </div>
            </button>
          </div>
          <div>
            <p className="mt-0 text-xs text-white"> Max Size: 256KB</p>
            <p className="text-xs text-red-500"> {errMsg}</p>
          </div>
          <div className="flex items-center justify-center gap-2">
            {verificationStatus !== 'VERIFIED' && (
              <div>
                <Button
                  type="button"
                  text="Verify Account"
                  handleClick={() => navigate('/home/profile/verifyID')}
                  textSize="text-xs"
                  py="py-2"
                  fontWeight="font-normal"
                />
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4 md:space-y-6">
          <div>
            <h3 className="mb-1 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              Name:
            </h3>
            <p className="text-sm font-medium text-black dark:text-white">
              {businessProfile?.data.name || ""}
            </p>
          </div>
          <div>
            <h3 className="mb-1 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              Email
            </h3>
            <p className="text-sm font-medium text-black dark:text-white">
              {userProfile?.data?.email || ""}
            </p>
          </div>
          <div className='flex gap-4'>
            <div>
              <h3 className="mb-1 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
               Contact number:
              </h3>
              <p className="text-sm font-medium text-black dark:text-white">
                {maskPhoneNumber('08186****385')}
              </p>
            </div>
            <button onClick={handleEditClick} className="flex items-end gap-2">
              <EditIcon className="w-4 h-4" />
              <p className="font-normal text-x10 text-primary">Edit</p>
            </button>
          </div>
        </div>
      </div>
      {!isLoading && !isError && (
        <div className="flex flex-col gap-4 px-4 pb-4 bg-white dark:bg-clrDarkBg rounded-b-md md:rounded-md md:px-8 md:py-6 md:mt-2 md:gap-7 second-profile-bg">
          <div className="flex flex-wrap md:flex-nowrap gap-y-4 justify-between xl:justify-start xl:gap-x-[10.5rem]">
            <div>
              <h3 className="mb-1 text-sm font-medium md:mb-2 text-clrGray dark:text-clrPlaceholder">
                Billing Type
              </h3>
              <div className='flex gap-2'>
                <p className="text-sm font-medium text-black md:text-lg">
                  {businessProfile?.data.billingType || ""}
                </p>
                <span className="block mb-0.5 md:mb-1 bg-clrGreen2 bg-opacity-20 py-[1px] px-1.5 rounded-lg self-end">
                  <p className="font-normal text-clrGreen2 text-x8">Basic</p>
                </span>
              </div>
              <div className='mt-4 md:mt-7'>
                {activePackage === undefined ? 
                  <Button
                    type="button"
                    text="Subscribe Now"
                    handleClick={() => navigate('/home/subscription')}
                    textSize="text-x9"
                    py="py-1"
                    fontWeight='font-normal'
                  /> 
                  : <Button
                      type="button"
                      text="Cancel Subscription"
                      handleClick={() => {toggleSubscription(true)}}
                      textSize="text-x9"
                      py="py-1"
                      bgColor='bg-clrRed3'
                      fontWeight='font-normal'
                    /> 
                }
              </div>
            </div>{' '}
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-sm md:mb-2 text-clrGray dark:text-clrPlaceholder">
                My Link Code:
              </h3>
              <p className="relative flex gap-2">
                <span className="text-xs font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {gettingLinkCode 
                    ? <ScaleLoader
                        loading={isLoading}
                        height={12}
                        width={2}
                        color={'#3d5170'}
                      />
                    : linkCode?.data?.linkCode}
                </span>
                <img
                  src={CopyIcon}
                  alt="Copy my link code"
                  width={13}
                  onClick={() => handleCopyClick(linkCode?.data?.linkCode || "")}
                  height={13}
                  className='cursor-pointer'
                />
                {linkCode?.data?.linkCode === copiedText && (
                  <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-primary text-x10 ">
                    {' '}
                    Copied
                  </p>
                )}
              </p>
            </div>
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-sm md:mb-2 text-clrGray dark:text-clrPlaceholder">
                API Key:
              </h3>
              <p className="relative flex gap-2">
                <span className="text-xs font-medium text-black md:text-base dark:text-white">
                  {truncateText(businessProfile?.data.apiKey || "", 5, 6)}
                </span>
                <img
                  src={CopyIcon}
                  alt="Copy this API key"
                  width={13}
                  onClick={() => handleCopyClick(businessProfile?.data.apiKey || "")}
                  height={13}
                  className='cursor-pointer'
                />
                {businessProfile?.data.apiKey === copiedText && (
                  <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-clrYellow text-x10 ">
                    {' '}
                    Copied
                  </p>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteAccount handleModal={() => setDeleteModal(false)} />
      )}
      {isSubscriptionCancelled && (
        <ChooseModal
          title={`Cancel Subscription`}
          description={`You are about to cancel this subscription from auto-renewal.`}
          handleButton={handleCancelSub}
          icon={<Delete2 className='w-16 h-16' />} 
          handleModal={() => toggleSubscription(!isSubscriptionCancelled)}   
          isLoading={cancelingSubscription}    
          buttonTitle="Cancel"
          btnColor="bg-[#DB5F5F]"
          errMsg={errMsg}
          close={true}
        />
      )}
    </section>
  );
};

export default ProfileInfo;
