import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Button } from "../reusables";

export interface AdsCaption {
  createdAtTimeStamp: number,
  caption: string,
  actions: string,
}

export const defaultDataAdsCaption: AdsCaption[] = [
  {
    createdAtTimeStamp: 123456789090888,
    caption: 'Watch these ads to access WiFi',
    actions: 'Active',
  },
  {
    createdAtTimeStamp: 123456789090888,
    caption: 'View the ads to commit to the WiFi',
    actions: 'Activate',
  },
]

// Advert Caption Column
const adsCaptionColumn = createColumnHelper<AdsCaption>();

export const adsCaption = [
  adsCaptionColumn.accessor("createdAtTimeStamp", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format('Do MMM YYYY | h:mm a')}</p>;
    },
  }),
  adsCaptionColumn.accessor("caption", {
    header: "Caption",
  }),
  adsCaptionColumn.accessor("actions", {
    header: "Actions",
    cell: (val) => {
      const actionStatus = val.getValue();
      const isActive = actionStatus === "Active";
      return (
        <div className="w-[40%]">
          <Button 
            text={isActive ? "Active" : "Activate"} 
            type="button" 
            handleClick={() => {}} 
            fontWeight="font-medium" 
            py="py-1.5"
            // disabled={isActive}
            bgColor={isActive ? "bg-clrGreen2" : "bg-primary"}
          />
        </div>
        );
      },
  }),
];