import { 
    AnalyticsOverviewDevice, 
    BandwidthInfoResponse, 
    CustomerOverviewResponse, 
    DataUsageAnalyticsResponse, 
    DataUsageResponse, 
    DeviceAnalyticsResponse, 
    DeviceAnalyticsSummary, 
    DeviceLiveSessionsSummary, 
    DeviceLivesSessionsSumaryResponse, 
    StatusHubCount, 
    TokenCountResponse, 
    UptimeAnalyticsRequest,
    UptimeAnalyticsResponse,
    UptimeSummary,
    VisitedDomainRequest,
    VisitedDomainResponse
} from './analyticsSliceTypes';
import { apiSlice } from './apiSlice';


const analyticsSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Device Analytics Customer Overview
        getCustomerOverview: build.query<CustomerOverviewResponse, void>({
            query: () =>
                `/v4/analytics-reports/customer-overview`,
            providesTags: ['Analytics'],
        }),

        //Get Device Analytics Status Hub Count
        getDeviceStatusHubCount: build.query<StatusHubCount, void>({
            query: () =>
                `/v4/analytics-reports/hub-count`,
            providesTags: ['Analytics'],
        }),

        //Get Device Tokens Count
        getDeviceTokensCount: build.query<TokenCountResponse, void>({
            query: () =>
                `/v4/analytics-reports/token-count`,
            providesTags: ['Analytics'],
        }),


        //Get Device Analytics Summary
        getDeviceAnalyticsSummary: build.query<DeviceAnalyticsResponse, DeviceAnalyticsSummary>({
            query: ({ search, page, pageSize }) =>
                `/v4/analytics-reports/devices?search=${search}&page=${page}&pageSize=${pageSize}`,
            providesTags: ['Analytics'],
        }),

        //Get Device Analytics Overview
        getDeviceAnalyticsOverview: build.query<AnalyticsOverviewDevice, string>({
            query: ( deviceId ) =>
                `/v4/analytics-reports/overview/device/${deviceId}`,
            providesTags: ['Analytics'],
        }),

        //Get Bandwith Information about a device
        getDeviceBandwidthInfo: build.query<BandwidthInfoResponse, string>({
            query: ( deviceId ) =>
                `/v4/analytics-reports/bandwidth/device/${deviceId}`,
            providesTags: ['Analytics'],
        }),

        //Get Device Analytics Live Sessions
        getLiveSessionAnalytics: build.query<DeviceLivesSessionsSumaryResponse, DeviceLiveSessionsSummary>({
            query: ({ search, page, pageSize, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/live-sessions?search=${search}&page=${page}&pageSize=${pageSize}`,
            providesTags: ['Analytics'],
        }),

         //Get Device Analytics Sessions
         getSessionAnalytics: build.query<DeviceLivesSessionsSumaryResponse, DeviceLiveSessionsSummary>({
            query: ({ search, page, pageSize, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/sessions?search=${search}&page=${page}&pageSize=${pageSize}`,
            providesTags: ['Analytics'],
        }),

        //Get Device Uptime Graph Request
        getUptimeGraphResults: build.query<UptimeAnalyticsResponse, UptimeAnalyticsRequest>({
            query: ({ timeLine, month, year, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/uptime?Timeline=${timeLine}&Month=${month}&Year=${year}`,
            providesTags: ['Analytics'],
        }),

        //Get Device Data usage Graph Request
        getDataUsageGraphResults: build.query<DataUsageAnalyticsResponse, UptimeAnalyticsRequest>({
            query: ({ timeLine, month, year, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/data-usage?Timeline=${timeLine}&Month=${month}&Year=${year}`,
            providesTags: ['Analytics'],
        }),

        //Get Uptime Summary
        getUptimeSummary: build.query<UptimeSummary, UptimeAnalyticsRequest>({
            query: ({ timeLine, month, year, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/uptime/summary?Timeline=${timeLine}&Month=${month}&Year=${year}`,
            providesTags: ['Analytics'],
        }),

         //Get Visited Domain
         getVisitedDomain: build.query<VisitedDomainResponse, VisitedDomainRequest>({
            query: ({ timeLine, year}) =>
            `/v4/visited-domain?TimeLine=${timeLine}&Year=${year}`,
            providesTags: ['Analytics'],
        }),

          //Get Data Usage Summary
        getDataUsageSummary: build.query<DataUsageResponse, UptimeAnalyticsRequest>({
            query: ({ timeLine, month, year, deviceId }) =>
            `/v4/analytics-reports/devices/${deviceId}/data-usage/summary?Timeline=${timeLine}&Month=${month}&Year=${year}`,
            providesTags: ['Analytics'],
        }),


    }),
    overrideExisting: true,
});

export const {
    useGetCustomerOverviewQuery,
    useGetDeviceStatusHubCountQuery,
    useGetDeviceAnalyticsSummaryQuery,
    useGetDeviceAnalyticsOverviewQuery,
    useGetDeviceBandwidthInfoQuery,
    useGetLiveSessionAnalyticsQuery,
    useGetSessionAnalyticsQuery,
    useGetUptimeGraphResultsQuery,
    useGetDataUsageGraphResultsQuery,
    useGetVisitedDomainQuery,
    useGetUptimeSummaryQuery,
    useGetDataUsageSummaryQuery,
    useGetDeviceTokensCountQuery
} = analyticsSlice;
