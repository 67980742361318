import { useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { trafficColumn } from "./data";
import { SearchRow } from "./common";
import { DoughnutChart } from "./DoughnutChart";
import { EmptyCard, TableLoader } from "../reusables";
import { useGetVisitedDomainQuery } from "src/features/api/analyticsSlice";
import { useParams } from "react-router-dom";


interface InfoBoxProps {
  label: string;
  value: number;
  infoBg: string;
}
const TrafficSummary = () => {
  
  const [page, setPage] = useState<number>(1);
  const {analyticId } = useParams();

  const { data, isLoading, isFetching } = useGetVisitedDomainQuery({
    deviceId: analyticId || '',
    timeLine: 'Yearly',
    year: "2024"
  });

  return (
    <div className="flex flex-col gap-4 mt-10 xl:flex-row">
    <TrafficAnalyticsChart />
    <div className="border border-[#C4C4C433] rounded-lg flex-1">
      <div className="p-5">
        <h4 className="mb-3 text-xs font-medium text-clrTextLightGray dark:text-white">
          Traffic Breakdown
        </h4>
        <SearchRow />
      </div>
      {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            defaultData={data?.data || []}
            columns={trafficColumn}
            totalPages={0}
            pageSize={30}
            currentPage={page}
            setPage={setPage}
          />
        )}

        {!isLoading && !isFetching && (data?.data.length || 0) === 0 && (
          <EmptyCard description="You don't have any traffic analytics!" />
        )}
    </div>
  </div>
  )
}

export default TrafficSummary

const TrafficAnalyticsChart = () => {
  const data = [
    { label: 'Social Media', value: 20, color: "rgba(51, 41, 1, 1)", infoBg: 'bg-[#332901]' },
    { label: 'Gaming', value: 10, color: "rgba(85, 68, 2, 1)", infoBg: 'bg-[#554402]' },
    { label: 'Sports', value: 10, color: "rgba(170, 135, 3, 1)", infoBg: 'bg-[#806603]' },
    { label: 'Betting', value: 20, color: "rgba(170, 135, 3, 1)", infoBg: 'bg-[#AA8703]' },
    { label: 'Education', value: 10, color: "rgba(255, 203, 5, 1)", infoBg: 'bg-[#FFCB05]' },
    { label: 'Crypto', value: 10, color: "rgba(255, 212, 47, 1)", infoBg: 'bg-[#FFD42F]' },
    { label: 'Religion', value: 10, color: "rgba(255, 220, 88, 1)", infoBg: 'bg-[#FFDC58]' },
    { label: 'Others', value: 10, color: "rgba(255, 229, 130, 1)", infoBg: 'bg-[#FFE582]' },
  ];

  return (
    <div className="space-y-6 md:space-y-12 border border-[#C4C4C433] px-4 md:px-6 md:pt-9 py-6 md:pb-14 rounded-lg">
      <DoughnutChart data={data} />
      <div className="grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3 ">
        {
          data.map((data) => {
            return <DynamicInfoBox key={data.label} label={data.label} value={data.value} infoBg={data.infoBg} />;
          })
        }
      </div>
    </div>
  );
};

const DynamicInfoBox = ({ label, value, infoBg }: InfoBoxProps) => (
  <div className="flex gap-2 md:w-[120px]">
    <div
      className={`w-1 rounded ${infoBg} h-[20px]`}
    />
    <div className={`flex justify-between items-center w-full`}>
      <p className={`text-[#4E5969] text-x10`}>{label}</p>
      <p className="text-[#1D2129] text-[15px] leading-[21px] font-medium">{value}
        <span className="font-normal text-[#86909C] text-x10">%</span>
      </p>
    </div>
  </div>
);