import { useRef, useState } from 'react';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { AuthApp, AuthLoginApp } from './SecurityModals';
import {
  useDeactivate2faMutation,
  useGetUserProfileQuery,
  useTwofaOptionsQuery,
} from 'src/features/api/authSlice';
import { ButtonLoader, ErrorMsg } from '../reusables';
// import { useNavigate } from 'react-router-dom';

const Security = () => {
  const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
  // const navigate = useNavigate();

  const { data } = useTwofaOptionsQuery();
  const [deactivate2FA, { isLoading, isError }] = useDeactivate2faMutation();

  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [isSecurityOpen, setIsSecurityOpen] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useOutsideClick(modalRef, handleCloseModal);

  const resetHandler = () => {
    !data?.data?.is2FaEnabled ? setShowModal(true) : deactivate2FA();
  };

  const handleLoginPin = () => {
    setIsSecurityOpen(true)
  };

  const { data: userProfile } = useGetUserProfileQuery(user?.userId, {
    skip: !user?.userId,
  });

  const securityItem = (title: string, description: string, type: string) => (
    <div className="grid lg:grid-cols-2 grid-cols-[1fr_30%] items-center justify-between pl-11 pr-5 gap-6">
      <div>
        <h2 className="mb-1 text-base font-semibold text-black">{title}</h2>
        <p className="text-[0.6875rem] font-normal text-[#6B6B6B]">
          {description}
        </p>
      </div>
      <button
        className={`flex cursor-pointer justify-center items-center lg:w-[113px] w-fit text-center h-9 mt-3 px-6 gap-3  rounded-[28px] transition duration-500 ease-in-out transform  hover:scale-[0.95] ${
          data?.data?.is2FaEnabled && type === '2fa'
            ? 'bg-clrRed3 hover:bg-[#DB5F5F]'
            : 'bg-primary hover:bg-[#e3be27]'
        }`}
        onClick={() =>
          type === '2fa'
            ? resetHandler()
            : handleLoginPin()
        }
        disabled={isLoading}
      >
        <p className="text-xs font-medium text-white">
          {type === '2fa' && isLoading ? (
            <ButtonLoader loading={isLoading} />
          ) : data?.data?.is2FaEnabled && type === '2fa' ? (
            'Deactivate'
          ) : (
            'Reset'
          )}
        </p>
      </button>
    </div>
  );

  return (
    <section className="bg-clrBg w-[96%] mx-auto mt-3.5 md:mt-0 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="bg-white rounded-lg pt-[2.125rem] pb-36 grid space-y-8 font-medium">
        {securityItem(
          'Login PIN',
          'Login password is used to log in to your account.',
          'pin'
        )}
        {securityItem(
          '2 Factor Authenticator',
          'Secure your account with an authenticator app',
          '2fa'
        )}

        {isError && <ErrorMsg errMsg={'Deactivation failed!'} />}
      </div>
      {showModal && (
        <AuthApp
          handleModal={() => setShowModal(false)}
          otp={otp}
          setOtp={setOtp}
          timer={'55secs'}
          formTitle={'Email Verification'}
          description={`Enter the 6 digit code OTP sent to your email`}
          email={`${userProfile?.data?.email}`}
        />
      )}

      {isSecurityOpen && (
        <AuthLoginApp
          handleModal={() => setIsSecurityOpen(false)}
          otp={otp}
          setOtp={setOtp}
          formTitle={'Authentication App'}
          description={`Enter the 6 digit code generated by your authenticator app`}
        />
      )}
    </section>
  );
};

export default Security;
