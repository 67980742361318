import { useRef, useState } from 'react';
import { ButtonYellow } from '../reusables';
import Export from '../../assets/icons/export.svg';
import useOutsideClick from "src/hooks/useOutsideClick";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { FilterRowProps, DropdownFilterProps, DropDownProps } from "./type";

const FilterRow: React.FC<FilterRowProps> = ({
  filterStatus,
  setFilterStatus,
  filterYear,
  setFilterYear,
  filterMonth,
  setFilterMonth,
}) => {
  const [option, toggleOption] = useState<string>("");
  const [yearOption, toggleYearOption] = useState<string>("");
  const [monthOption, toggleMonthOption] = useState<string>("");

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
    toggleYearOption("");
    toggleMonthOption("");
  };
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col flex-wrap w-full gap-x-3 md:flex-row md:w-auto mb-6 md:mb-8">
        <MobileFilterSearch>
          <Filter
            options={[
              {label: 'By Day', value: 'Daily'},
              {label: 'By Month', value: 'Monthly'},
              {label: 'By Year', value: 'Yearly'},
            ]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={'TIMELINE'}
            selectFilterOption={setFilterStatus}
            selectedOption={filterStatus}
            modalRef={modalRef}
            label={'Timeline'}
          />
          {filterStatus === 'Daily' || filterStatus === 'Monthly' ? (
            <Filter
              options={[
                { label: 'January', value: '01' },
                { label: 'February', value: '02' },
                { label: 'March', value: '03' },
                { label: 'April', value: '04' },
                { label: 'May', value: '05' },
                { label: 'June', value: '06' },
                { label: 'July', value: '07' },
                { label: 'August', value: '08' },
                { label: 'September', value: '09' },
                { label: 'October', value: '10' },
                { label: 'November', value: '11' },
                { label: 'December', value: '12' },
              ]}
              toggleFilter={toggleMonthOption}
              selectedFilterOption={monthOption}
              currentFilterOption={'MONTH'}
              selectFilterOption={setFilterMonth}
              selectedOption={filterMonth}
              modalRef={modalRef}
              label={'Month'}
            />
          ) : null}

          <Filter
            options={['2024', '2025']}
            toggleFilter={toggleYearOption}
            selectedFilterOption={yearOption}
            currentFilterOption={'YEAR'}
            selectFilterOption={setFilterYear}
            selectedOption={filterYear}
            modalRef={modalRef}
            label={'Year'}
          />
        </MobileFilterSearch>
        <div className="mt-2 md:mt-3">
          <ButtonYellow text="Export" btnIcon={Export} to={''} />
        </div>
      </div>
    </div>
  );
};

export const Filter = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}: DropdownFilterProps) => {
  // Find the label for the selected value
  const selectedLabel = (() => {
    const option = options.find(opt =>
      typeof opt === 'object' && 'value' in opt && opt.value === selectedOption
    );
    return typeof option === 'object' && 'label' in option
      ? option.label
      : selectedOption;
  })();

  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
        {label}:
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative h-8 px-4 w-full md:w-40 border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl"
      >
        <p className="text-[10px] font-normal text-clrPlaceholder">
          {selectedLabel}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownOptions
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
          widthFull
        />
      )}
    </div>
  );
};


// Updated DropDownOptions component
export const DropDownOptions = ({
  list,
  modalRef,
  handleSelect,
  closeDropdown,
  widthFull,
}: DropDownProps) => {
  return (
    <div>
      <div className={`absolute z-30 overflow-y-auto max-h-48 ${widthFull ? "w-full" : "w-48"} p-3 pb-4 overflow-hidden bg-white rounded dark:bg-clrDarkBg whitespace-nowrap text-ellipsis`}>
        <ul className="space-y-6">
          {list.map((each, index) => {
            const optionLabel = typeof each === 'string' ? each : each.label;
            const optionValue = typeof each === 'string' ? each : each.value;
            return (
              <li
                key={index}
                onClick={() => {
                  handleSelect(optionValue);
                  closeDropdown("");
                }}
                className="text-xs font-normal transition duration-150 cursor-pointer text-clrGray dark:text-clrPlaceholder hover:text-primary dark:hover:text-primary"
              >
                {optionLabel}
              </li>
            );
          })}
        </ul>
      </div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
    </div>
  );
};



export default FilterRow;
