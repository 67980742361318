import SecondaryNav from '../reusables/SecondaryNav';

const HotspotHubs = () => {
  return (
    <SecondaryNav
      paths={[
        { path: '/home/hotspot-hubs/my-hubs', title: 'My Hubs' },
        { path: '/home/hotspot-hubs/live-session', title: 'Live Session' },
        { path: '/home/hotspot-hubs/hubs-report', title: 'Hubs Report' },
        { path: '/home/hotspot-hubs/managed-hubs', title: 'Managed Hubs' },
      ]}
    />
  );
};

export default HotspotHubs;
