import { apiSlice } from "./apiSlice";
import { ActivePackageResponse, CancelSubscriptionResponse, PackageDetailResponse, PackageResponse, SubscriptionSuccess, SubsscriptionDetail } from "./subscriptionSliceTypes";

const subscriptionSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Subscription packages 
        getPackages: build.query<PackageResponse, void>({ 
            query: () => `/v4/subscriptions/packages`,
        }),

        //Get a user's active package
        getActivePackage: build.query<ActivePackageResponse, string>({ 
            query: (userId) => `/v4/subscriptions/user/${userId}`,
        }),

        //Get package detail
        getPackage: build.query<PackageDetailResponse, string>({ 
            query: (packageId) => `/v4/subscriptions/packages/${packageId}`,
        }),

        //Subscribe to a package 
        susbcribePackage: build.mutation<SubscriptionSuccess, SubsscriptionDetail>({
            query: ({detail, packageId, currencyId}) => ({
                url: `/v4/subscriptions/packages/${packageId}/currency/${currencyId}`,
                method: "POST",
                body: detail
            }),
            invalidatesTags: ["Subscription"]
        }),

         //Cancel user subscription
         cancelSubscription: build.mutation<CancelSubscriptionResponse, Partial<string>>({
            query: (subscriptionId) => ({
                url: `/v4/subscriptions/${subscriptionId}/cancel`,
                method: "PUT",
            }),
            invalidatesTags: ["Subscription"]
        }),
        
    }),
    overrideExisting: true,
})

export const { 
    useGetPackagesQuery,
    useLazyGetPackageQuery,
    useSusbcribePackageMutation,
    useGetActivePackageQuery,
    useCancelSubscriptionMutation,
 } = subscriptionSlice